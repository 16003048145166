import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('footer-loading')
export class FooterLoading extends LitElement {
  static get styles() {
    return css`
      .footer {
        height: 300px;
        background: var(--colorGradientPrimary);
      }
    `;
  }

  render(): TemplateResult {
    return html`
      <footer class="footer">
        <div class="upper-footer">
          <div class="container"></div>
        </div>
        <div class="lower-footer">
          <div class="container">
            <div class="brand">
              <div class="lower-content"></div>
            </div>
          </div>
        </div>
      </footer>
    `;
  }
}
