import { html, LitElement, nothing } from 'lit';
import { domPurify } from '../../utils/domPurify';
import { map } from 'lit/directives/map.js';
import { classMap } from 'lit/directives/class-map.js';
import { property, state } from 'lit/decorators.js';

// Styles
import styles from './Footer-css';

// Components
import brandLogo from '../../shared-templates/brandLogo';
import SocialIcons from './templates/socialIcons';
import './templates/loading';

// Utils
import debounce from '../../utils/debounce';
import { isDataStale, setData } from '../../utils/data';

// Constants
import { novantHealthUrl } from '../../constants';

//Types
import { IFooterData } from '../../types/types';

export class AuroraFooter extends LitElement {
  @property({ type: Boolean, attribute: 'hide' })
  _hide = false;

  @property({ type: Boolean, state: true })
  _loading = false;

  @property({ type: Number, state: true })
  _pageWidth = window.innerWidth;

  @property({ type: Object, attribute: false })
  footerdata: IFooterData = {
    footerStyle: [],
  };  

  @property({ type: Array, state: true })
  _itemsOpen: Array<number> = [];
   
  @state()
  _isOpen = false;

  static get styles() {
    return [styles];
  }

  private handleResize = () => {
    this._pageWidth = window.innerWidth;
    this.requestUpdate();
    this._loading = false;
  };

  private async fetchDefaultData(url: string) {
    if (isDataStale('footer', 'aurora-footer')) {
      this._loading = false;
      this.footerdata = JSON.parse(sessionStorage.getItem('footer'));
    } else {
      this._loading = true;
      const response = await fetch(url);
      const json = await response.json();
      this.footerdata = json.footer;
      this._loading = false;
      setData('footer', this.footerdata, 'aurora-footer');
    }
  } 

  //Functions from menuList
  private toggleOpen (index: number) {  
    if (this._itemsOpen.includes(index)){
      this._itemsOpen = this._itemsOpen.filter(item => item !== index)
      this.requestUpdate();
      return 
    }
    this.close();
    this._itemsOpen.push(index)
    this.requestUpdate();
  };

  close() {
    this._itemsOpen.pop();
  }

  private handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this._isOpen = !this._isOpen;
    }
  };

  async connectedCallback() {
    // check query params for 'hide-aurora-footer' and set _hide to true if found
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-footer')) {
      this._hide = true;
    }

    window.addEventListener('resize', debounce(this.handleResize, 200));
    super.connectedCallback();

    await this.updateComplete;

    if (this.getAttribute('footerdata') === null) {
      await this.fetchDefaultData(`${novantHealthUrl}/api/navigation/footer`);
    }

    const attr = this.getAttribute('footerdata');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.footerdata = JSON.parse(this.getAttribute('footerdata'));
      this.setAttribute('footerdata', '{"loaded": true}');      
    }
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.handleResize);
    super.disconnectedCallback();
  }

  render() {
    const { upperFooter, lowerFooter, language, image, logoUrl } = this.footerdata;    
        
    if (this._hide) {
      return nothing;
    }

    return this._loading
      ? html`<footer-loading></footer-loading>`
      : html`
        <footer 
          class="footer" 
          lang="${language}"
        >
          ${upperFooter?.length > 0
            ? html`
              <div class="upper-footer">
                <div class="container">
                  ${upperFooter?.map((col, index) => { 
                    return html`  
                      <div class="${classMap({
                        'footer-col': true,
                        })}"
                      >
                        ${col.columnContent.map((col) => { 
                          return html`
                            <ul class="menu-list">
                              ${window.innerWidth < 1200
                                ? html`
                                  <li
                                    class="${classMap({
                                      'menu-list-section': true,
                                      'open': this._itemsOpen.includes(index),
                                      })}" 
                                    @click="${() => this.toggleOpen(index)}"
                                    @keyup="${this.handleKeyPress}"
                                    aria-label="Open Toggle sub-navigation"
                                  >
                                    ${col.sectionTitle}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="8"
                                      viewBox="0 0 14 8"
                                      fill="none"
                                    >
                                      <path
                                        d="M13 7L7 1L1 7"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    ${col.menuListContent &&
                                      html`
                                        <ul class="menu-list-links">
                                          ${col.menuListContent.map((list) => {
                                            return html` ${list.contentType === 'RichText'
                                              ? html`
                                                <li class="menu-list-items" 
                                                  .innerHTML=${domPurify(list.value)}>
                                                </li>
                                              `
                                              : html`
                                                <li class="menu-list-items">
                                                  <a
                                                    href=${list.url}
                                                    target=${`${list.openInNewWindow ? '_blank' : '_self'}`}
                                                    aria-label=${list.title}
                                                  >
                                                    ${list.title}
                                                  </a>
                                                </li>
                                              `
                                            }`;
                                          })}
                                        </ul>
                                      `
                                    }
                                  </li>
                                `
                                : html` 
                                  <li class="menu-list-section">
                                    ${col.sectionUrl
                                      ? html`
                                        <a 
                                          href=${col.sectionUrl} 
                                          class="menu-list-section-link"
                                        > 
                                          ${col.sectionTitle} 
                                        </a>
                                      `
                                      : html` <span>${col.sectionTitle}</span>`
                                    }
                                    ${col.menuListContent &&
                                      html`
                                        <ul class="menu-list-links">
                                          ${col.menuListContent.map((list) => {
                                            return html `
                                              ${list.contentType === 'RichText'
                                                ? html` <li class="menu-list-items" .innerHTML="${domPurify(list.value)}"></li> `
                                                : html`
                                                  <li class="menu-list-items">
                                                    <a
                                                      href=${list.url}
                                                      target=${`${list.openInNewWindow ? '_blank' : '_self'}`}
                                                      aria-label=${list.title}
                                                    >
                                                      ${list.title}
                                                    </a>
                                                  </li>
                                                `
                                              }
                                            `
                                          })}
                                        </ul>
                                      `
                                    }
                                  </li>
                                `
                              }                                
                            </ul>
                          ` 
                        })}
                      </div>
                    `
                  })}
                </div>
                <div class="brand">
                  ${image && brandLogo({ image, logoUrl })}
                  ${lowerFooter?.socialIcons
                    ? SocialIcons(lowerFooter.socialIcons, novantHealthUrl)
                    : nothing}
                </div>
              </div>
            `
            : nothing
          }
          ${lowerFooter?.lowerFooterContent.length > 0
            ? html`
              <div class="lower-footer">
                <div class="container">
                  <div class="lower-content">
                    ${lowerFooter &&
                      map(lowerFooter?.lowerFooterContent, (item) => {
                        return html`
                          <div class="${item.displayOption}">
                            ${item.columnContent &&
                              map(item.columnContent, (col, index) => { 
                                return html`
                                  <ul class="menu-list">
                                    ${window.innerWidth < 1200
                                      ? html`
                                        <li
                                          class="menu-list-section"
                                          @click="${() => this.toggleOpen(index)}"
                                          @keyup="${this.handleKeyPress}"
                                          aria-label="Open Toggle sub-navigation"
                                        >
                                          ${col.sectionTitle}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="8"
                                            viewBox="0 0 14 8"
                                            fill="none"
                                          >
                                            <path
                                              d="M13 7L7 1L1 7"
                                              stroke="white"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                          ${col.menuListContent &&
                                            html`
                                              <ul class="menu-list-links">
                                                ${col.menuListContent.map((list) => {
                                                  return html` 
                                                    ${list.contentType === 'RichText'
                                                      ? html` 
                                                        <li class="menu-list-items"
                                                          .innerHTML="${domPurify(list.value)}">
                                                        </li>`
                                                      : html`
                                                        <li class="menu-list-items">
                                                          <a
                                                            href=${list.url}
                                                            target=${`${list.openInNewWindow ? '_blank' : '_self'}`}
                                                            aria-label=${list.title}
                                                          >
                                                            ${list.title}
                                                          </a>
                                                        </li>
                                                      `
                                                    }
                                                  `;
                                                })}
                                              </ul>
                                            `
                                          }
                                        </li>
                                      `
                                      : html` 
                                        <li class="menu-list-section">
                                          ${col.sectionUrl
                                            ? html`
                                                <a 
                                                  href=${col.sectionUrl} 
                                                  class="menu-list-section-link"
                                                > 
                                                  ${col.sectionTitle} 
                                                </a>
                                              `
                                            : html`
                                              <span>
                                                ${col.sectionTitle}
                                              </span>
                                            `
                                          }
                                          ${col.menuListContent &&
                                            html`
                                              <ul class="menu-list-links">
                                                ${col.menuListContent.map((list) => {
                                                  return html` 
                                                    ${list.contentType === 'RichText'
                                                      ? html`
                                                        <li class="menu-list-items"
                                                          .innerHTML=${domPurify(list.value)}>
                                                        </li> 
                                                      `
                                                      : html`
                                                        <li class="menu-list-items">
                                                          <a
                                                            href=${list.url}
                                                            target=${`${list.openInNewWindow ? '_blank' : '_self'}`}
                                                            aria-label=${list.title}
                                                          >
                                                            ${list.title}
                                                          </a>
                                                        </li>
                                                      `
                                                    }
                                                  `
                                                })}
                                              </ul>
                                            `
                                          }
                                        </li>
                                      `
                                    }                                
                                  </ul>
                                `
                              })
                            }
                          </div>
                        `;
                      })
                    }
                  </div>
                </div>
              </div>
            `: nothing
          }
        </footer>
      `;
  }
}

