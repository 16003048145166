import { getCookie, setCookie } from './cookie';

export const setData = (storageItem: string, data: object, cookieName: string) => {
  sessionStorage.setItem(storageItem, JSON.stringify(data));
  setCookie(cookieName, 1);
};

export const isDataStale = (storageItem: string, cookie: string) => {
  if (sessionStorage.getItem(storageItem) && getCookie(cookie)) {
    return true;
  }
  return false;
};
