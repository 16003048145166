/* eslint-disable no-constant-condition */
import { html } from 'lit';
import { domPurify } from '../utils/domPurify';

// Utils
import imageIsFullPath from '../utils/imageIsFullPath';

// Constants
import { novantHealthUrl } from '../constants/';

// Types
import { Image } from '../types/types';

interface Brand {
  image: Image;
  logoUrl?: string;
}

function brandLogo({ image, logoUrl }: Brand) {
  return html`
    <div class="brand-logo">
      ${image?.contentType === 'Image'
        ? html`<a href=${logoUrl} class="brand-image-button">
            <img
              src=${imageIsFullPath(image?.imageSrc)
                ? image.imageSrc
                : novantHealthUrl + image?.imageSrc}
              alt=${image?.altText}
            />
          </a>`
        : html`<a href=${logoUrl} title=${image?.altText} class="brand-image-button"
            .innerHTML=${domPurify(image?.imageSrc)}>
          </a>`}
    </div>
  `;
}

export default brandLogo;
