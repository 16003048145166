import { html } from 'lit';
import { domPurify } from '../../../utils/domPurify';
import { map } from 'lit/directives/map.js';
import imageIsFullPath from '../../../utils/imageIsFullPath';

interface Icon {
  image: {
    imageSrc: string;
    altText: string;
    contentType: string;
  };
  title: string;
  url: string;
  openInNewWindow: boolean;
}

const SocialIcons = (data: object[], novantHealthUrl: string) => {
  return html`
    <div class="social">
      <ul class="social-list">
        ${map(
          data,
          (item: Icon) => html`
            <li class="social-list-item">
              ${item.image.contentType === 'Image'
                ? html`
                    <a
                      href="${item.url}"
                      aria-label="${item.title}"
                      target="${item.openInNewWindow ? '_blank' : '_self'}"
                    >
                      <img
                        src="${imageIsFullPath(item.image.imageSrc)
                          ? item.image.imageSrc
                          : novantHealthUrl + item.image.imageSrc}"
                        alt="${item.image.altText}"
                      />
                    </a>
                  `
                : html`
                    <a
                      href="${item.url}"
                      aria-label="${item.title}"
                      target="${item.openInNewWindow ? '_blank' : '_self'}"
                      .innerHTML=${domPurify(item.image.imageSrc)}
                    >
                    </a>
                  `}
            </li>
          `,
        )}
      </ul>
    </div>
  `;
};

export default SocialIcons;
